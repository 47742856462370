


































































































































































































































































import VuSendMessageExtra from '@/components/chat/ChatExtra/VuSendMessageExtra.vue'
import ChatBackups from '@/components/chat/ChatExtra/ChatBackups.vue'
import ExportConfig from '../../components/chat/ChatExtra/ExportConfigs.vue'
import ImportConfig from '../../components/chat/ChatExtra/ImportConfig/ImportConfigs.vue'
import TransferLicense from '../../components/chat/ChatExtra/TransferLicense.vue'
import RemoveForPeriod from '@/components/chat/ChatExtra/RemoveForPeriod/RemoveForPeriod.vue'
import AddAdmin from '@/components/AddAdmin/AddAdmin.vue'
import { InputSetups } from '@/mixins/input-setups'
import { AddAdminType } from '@/includes/types/AddAdmin/Enums'
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins, Ref } from 'vue-property-decorator'
import ChatActions from "@/components/chat/ChatActions.vue";

@Component({
  components: {
    ChatActions,
    PermissionsBanner,
    AddAdmin,
    'vu-send-message-extra': VuSendMessageExtra,
    ChatBackups,
    ExportConfig,
    ImportConfig,
    TransferLicense,
    RemoveForPeriod,
    Icon,
    PageTitle,
    ConfigField,
    HighlightAnchor
  },
  data() {
    return {
      AddAdminType,
      SitePermissionEnum
    }
  }
})
export default class Extra extends Mixins<InputSetups>(InputSetups) {
  @Ref('export-chat-configs') exportConfig!: ExportConfig

  isImportModalOpen = false

  exportSettings = false

  isTransferLicenseModalOpen = false

  get canChangeConfig() {
    return this.$store.getters.canChangeConfig
  }

  get chat() {
    return this.$store.state.chatState.chat
  }

  exportSettingsHandler(): void {
    const { triggers } = this.$store.state.triggersState

    if (triggers && (triggers.active.length || triggers.disabled.length)) {
      this.exportSettings = true
    } else {
      this.exportConfig.export_chat_config = true
      this.exportConfig.exportSettings()
    }
  }

  handleChatVisibilityButtonClick(): void {
    const action = this.chat.hidden ? 'showchat' : 'hidechat'

    this.$store.dispatch('runChatAction', { action, chat_id: this.$store.getters.chatId })
  }

  mounted(): void {
    if (this.$store.getters.isChatSet && !this.$store.state.triggersState.triggers) {
      this.$store.dispatch('updateTriggers')
    }
  }
}
