






























































































import { BaseChatInfo, ChatConfigSettings } from "@/includes/types/Chat/types";

import HighlightAnchor from "piramis-base-components/src/components/HighlightAnchor.vue";

import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'

type ActionKeys = 'enabled' | 'silent_for_users' | 'silent_for_all' | 'hidden'

@Component({
  components: {
    HighlightAnchor
  }
})
export default class ChatActions extends Vue {

  @Prop() chat!: BaseChatInfo

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Prop({ type: Boolean, default: true }) hasAnchor!: boolean

  @Prop({ default: () => [ 'enabled', 'silent_for_users', 'silent_for_all', 'hidden' ] }) items!: Array<ActionKeys>

  @Emit()
  updateChatFields(chat: ChatConfigSettings): Partial<ChatConfigSettings> {
    const { enabled, silent_for_users, silent_for_all, hidden } = chat

    return {
      hidden,
      enabled,
      silent_for_users,
      silent_for_all
    }
  }

  isChatActionActive(key: keyof BaseChatInfo): boolean {
    return !this.chat[key]
  }

  get chatId() {
    return this.chat.chat_id
  }

  handleSilentForAllButtonClick(): void {
    const action = this.chat.silent_for_all ? 'disablechatsilentforall' : 'enablechatsilentforall'

    this.runChatAction(action)
  }

  runChatAction(action: string) {
    return this.$store.dispatch('runChatActionReq', { action, chat_id: this.chatId })
      .then(res => {
        if (res) {
          this.updateChatFields(res)
        }
      })
  }

  handleDisableChatButtonClick(): void {
    const action = this.chat.enabled ? 'disablechat' : 'enablechat'

    this.runChatAction(action)
  }

  handleSilentForUsersButtonClick(): void {
    const action = this.chat.silent_for_users ? 'disablechatsilentforusers' : 'enablechatsilentforusers'

    this.runChatAction(action)
  }

  handleChatVisibilityButtonClick(): void {
    const action = this.chat.hidden ? 'showchat' : 'hidechat'

    this.runChatAction(action)
  }
}
